import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import noPic from 'src/assets/images/no-photo.png'
import OrderController from 'src/platform/api/order/order';
import { RouteComponentProps, withRouter } from 'react-router';
import SellerOrderMapDetail from './SellerOrderMapDetail';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IProps extends RouteComponentProps {
  sellersOrdersMapList: Array<any>,
}

interface IState {
  requestIsOff: boolean,
  page: number,
  size: number,
  totalPages: number,
  loaded: boolean;
  isFirstInit: boolean,
  activeId: number,
  details: number,
  orderClusterId: string,
  sellersOrdersLeftBarList: any,
}

const {
  Agent_,
  Order_,
  Amount,
  Point,
} = Languages.Translations

const MapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBxeJrquKa5IdsYWYP-_RhoN6p1bGt4ZCI&libraries=places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: any) =>
  <GoogleMap
    defaultZoom={12}
    center={props.array && { lat: 40.1872023, lng: 44.51520900000003 }}
  >
    {props.array && props.array.map((item: any, index: number) => {
      return <InfoBox
        key={index}
        position={new google.maps.LatLng(item.latitude, item.longitude)}
        options={{
          closeBoxURL: ``,
          enableEventPropagation: true,
          pixelOffset: new google.maps.Size(-35, -25),
        }}>
        <div
          className="G-cursor"
          style={{
            width: "95px",
            height: "40px",
          }}>
          <div className={`P-marker ${props.orderClusterId === item.id ? "P-marker-active" : ""}`}
            onClick={() => { props.getSellersOrdersLeftBarList(item.id) }}>
            {item.weight} kg
          </div>
        </div>
      </InfoBox>
    })}
  </GoogleMap>
)

class OrdersMap extends HelperComponent<IProps, IState> {

  public state: IState = {
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    loaded: false,
    isFirstInit: true,
    activeId: null,
    details: null,
    orderClusterId: "",
    sellersOrdersLeftBarList: null,
  }

  public inputTimer: any;

  // Left bar
  private getSellersOrdersLeftBarList = async (clusterId: string) => {
    const {
      page,
      size,
    } = this.state;

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await OrderController.getSellersOrdersLeftBarList({
        page,
        size,
        orderClusterId: clusterId,
      });
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));
      if (result.success) {
        this.asyncSetState({
          sellersOrdersLeftBarList: result.data,
          totalPages: result.data.totalPages,
          orderClusterId: clusterId,
          loaded: true,
          isFirstInit: this.state.isFirstInit && !result.data.length
        });
      }
      else  store.addNotification(Helper.notificationConfig(result.message, false));

    }, 300);
  };


  loadMore = async () => {
    const { requestIsOff, sellersOrdersLeftBarList, page, size, totalPages, orderClusterId } = this.state;

    if (!requestIsOff && page < totalPages) {
      this.asyncSetState({ requestIsOff: true })
      const res = await OrderController.getSellersOrdersLeftBarList({
        page: this.state.page + 1,
        size,
        orderClusterId,
      });
      if (res && res.success) {
        this.asyncSetState({
          sellersOrdersList: [...sellersOrdersLeftBarList, ...res.data.content],
          page: this.state.page + 1,
          totalPages: res.data.totalPages,
        });
        this.asyncSetState({ requestIsOff: false });
      }
      else{
        store.addNotification(Helper.notificationConfig(res.message, false));
      }
    }
  }

  public render() {

    const { sellersOrdersLeftBarList, orderClusterId } = this.state;

    return (
      <section className="P-orders-map G-flex">
        {sellersOrdersLeftBarList ? <section className="P-seller-orders-details">
          <div className="G-flex">
            <div className="P-left">
              <div className="P-total G-column">
                <div className="G-flex G-justify-between">
                  <div className="G-flex G-align-center G-mb-1">
                    <i className="icon-ic_map G-mr-1 P-green" />
                    <div className="G-bold">{sellersOrdersLeftBarList.addressName}</div>
                  </div>
                  <i className="icon-ic_back G-mr-1 P-green" onClick={() => this.asyncSetState({ sellersOrdersLeftBarList: null })} />
                </div>
                {sellersOrdersLeftBarList.buyerId ? <>
                  <div className="G-mb-1 P-seller">{Agent_}</div>
                  <div className="G-flex G-mb-2">
                    <div className="G-flex P-image"
                      style={{
                        background: `url(${sellersOrdersLeftBarList.buyerImageUrl
                          ? `${sellersOrdersLeftBarList.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat`
                      }}/>
                    <span>{sellersOrdersLeftBarList.buyerName}</span>
                  </div> 
                </> : ""}
                <div className="P-bottom G-flex G-justify-between">
                  <div className="P-box">
                    <div className="P-one P-green G-bold">{sellersOrdersLeftBarList.orderCountInCluster}</div>
                    <div className="P-two G-bold">{Order_}</div>
                  </div>
                  <div className="P-box">
                    <div className="P-one P-green G-bold">{sellersOrdersLeftBarList.amountInCluster !== 0 ? sellersOrdersLeftBarList.amountInCluster.toFixed(2) : sellersOrdersLeftBarList.amountInCluster}</div>
                    <div className="P-two G-bold">{Amount}</div>
                  </div>
                  <div className="P-box">
                    <div className="P-one P-green G-bold">{sellersOrdersLeftBarList.pointInCluster}</div>
                    <div className="P-two G-bold">{Point}</div>
                  </div>
                </div>
              </div>
              {sellersOrdersLeftBarList && sellersOrdersLeftBarList.sellerListInCluster.content.map((item: any, index: number) => {
                return <SellerOrderMapDetail item={item} key={index} />
              })}
            </div>
          </div>
        </section > : null}
        <div style={{ height: "auto", width: '100%' }}>
          <MapComponent
            array={this.props.sellersOrdersMapList}
            getSellersOrdersLeftBarList={this.getSellersOrdersLeftBarList}
            orderClusterId={orderClusterId}
          />
        </div>
      </section>
    );
  }
}

export default withRouter(OrdersMap);